import React, { useEffect, useState } from "react";
import { View, Page, StyleSheet, Text } from "@react-pdf/renderer";
import InvoiceTitle from "./pdf/InvoiceTitle";
import InvoiceRecipient from "./pdf/InvoiceRecipient";
import InvoiceNo from "./pdf/InvoiceNo";
import InvoiceDates from "./pdf/InvoiceDates";
import TableInvoice from "../../components/Pdf/TableGenerator";
import {
  getDate,
  lastDayofMonth,
  firstDayofMonth,
} from "../../utils/date";
import InvoiceBankDetails from "views/Common/pdf/InvoiceBankDetails";
import InvoiceSignature from "views/Common/pdf/InvoiceSignature";

const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  DatesContainer: {
    paddingBottom: 20,
    textAlign: "right",
  },
  InvoiceNoContainer: {
    paddingBottom: 30,
  },
  RecipientContainer: {
    paddingTop: 40,
    paddingBottom: 35,
    maxWidth: "30%",
    alignSelf: "flex-end",
  },
  TableContainer: {
    marginTop: 10,
  },
  totalTableContainer: {
    marginTop: 60,
    maxWidth: "50%",
    alignSelf: "flex-end",
  },
  NoTvaContainer: {
    fontSize: 8,
  },
  BankDetailsContainer: {
    paddingTop: 40,
  },
  PenaltyContainer: {
    fontSize: 8,
    paddingTop: 40,
  },
  SignatureContainer: {
    marginLeft: 'auto',
    paddingTop: 40,
  },
});

const Preview = (props) => {
  const { item } = props;
  const [editedItem, setEditedItem] = useState(item);

  useEffect(() => {
    setEditedItem(item);
  }, [item])

  const tableform = !editedItem.includeTva
    ? {
      headers: [
        { title: "Description", size: "50%" },
        { title: "Quantité", size: "10%" },
        { title: "P.U HT", size: "20%" },
        { title: "Montant HT", size: "20%" },
      ],
      rows: editedItem?.lines?.map((line) => (
        {
          description: line.serviceName,
          quantity: line.quantity,
          priceHt: `${Number.parseFloat(line.priceHt).toFixed(2)} ${editedItem.currency}`,
          totalPriceHt: `${Number.parseFloat(line.totalPriceHt).toFixed(
            2
          )} ${editedItem.currency}`,
        }))
    }
    : {
      headers: [
        { title: "Description", size: "50%" },
        { title: "Quantité", size: "10%" },
        { title: "P.U HT", size: "14%" },
        { title: "TVA", size: "8%" },
        { title: "Montant HT", size: "18%" },
      ], rows: editedItem?.lines?.map((line) => (
        {
          description: line.serviceName,
          quantity: line.quantity,
          totalPrice: `${Number.parseFloat(line.priceHt).toFixed(2)} ${editedItem.currency}`,
          tva: `${Number.parseFloat(line.tvaPercentage).toFixed(2)}%`,
          totalPriceHt: `${Number.parseFloat(line.totalPriceHt).toFixed(
            2
          )} ${editedItem.currency}`,
        }))
    };

  const tableTotalform = !editedItem.includeTva
    ? {
      css: {
        header: {
          view: { backgroundColor: "white" },
          text: { fontSize: 10 },
        },
        row: {
          view: { backgroundColor: "#e9ecef" },
          text: { fontSize: 10 },
        },
      },
      headers: [
        {
          title: "Sous-total HT",
          size: "60%",
          backgoundColor: "#bff0fd",
        },
        {
          title: `${Number.parseFloat(editedItem.totalPriceHt).toFixed(2)} ${editedItem.currency
            }`,
          size: "40%",
          backgoundColor: "#bff0fd",
        },
      ],
      rows: [
        {
          totalPrice: "Total HT",
          subTotalPriceHT: `${Number.parseFloat(
            editedItem.totalPriceHt
          ).toFixed(2)} ${editedItem.currency}`,
        },
      ],
    }
    : {
      css: {
        header: {
          view: { backgroundColor: "white" },
          text: { fontSize: 10 },
        },
        row: {
          view: { backgroundColor: "white" },
          text: { fontSize: 10 },
        },
      },
      headers: [
        {
          title: "Total HT",
          size: "56%",
          backgoundColor: "#bff0fd",
        },
        {
          title: `${Number.parseFloat(editedItem.totalPriceHt).toFixed(2)} ${editedItem.currency}`,
          size: "44%",
          backgoundColor: "#bff0fd",
        },
      ],
      rows: [
        {
          tva: `TVA`,
          tvaPrice: `${Number.parseFloat(editedItem.tvaAmount).toFixed(2)} ${editedItem.currency}`,
        },
        {
          tva: "Total TTC",
          subTotalPriceHT: `${Number.parseFloat(editedItem.totalPrice).toFixed(2)} ${editedItem.currency}`,
        },
      ],
    };

  return (
    <Page style={styles.page} size="A4" wrap>
      <InvoiceTitle
        item={{
          commercialName: editedItem.commercialName,
          address: editedItem.address,
          mail: editedItem.mail,
          phone: editedItem.phone,
          siret: editedItem.siret,
          tva: editedItem.tvaNumber,
          includeTva: editedItem.includeTva,
          includeLogo: editedItem.includeLogo,
          logo: editedItem.logo,
        }}
      />
      <View style={styles.RecipientContainer}>
        <InvoiceRecipient
          item={{
            name: editedItem.clientName,
            address: editedItem.clientAddress,
          }}
        />
      </View>
      <View style={styles.InvoiceNoContainer}>
        <InvoiceNo
          item={{
            reference: editedItem.reference,
          }}
        />
      </View>
      <View style={styles.DatesContainer}>
        <InvoiceDates
          item={{
            executionDate: getDate(editedItem.creationDate),
            billingDate: `${getDate(
              firstDayofMonth(editedItem.serviceExecutionDate),
              true
            )} to ${getDate(
              lastDayofMonth(editedItem.serviceExecutionDate),
              true
            )}`,
            paimentDate: getDate(editedItem.paimentDate),
          }}
        />
      </View>
      <View style={styles.TableContainer}>
        <TableInvoice form={tableform} />
      </View>
      <View style={styles.totalTableContainer}>
        <TableInvoice form={tableTotalform} />
        {!editedItem.includeTva && (
          <Text style={styles.NoTvaContainer}>
            {
              "TVA non applicable, article 293B du code général des impôts"
            }
          </Text>
        )}
      </View>
      <View style={{ flexDirection: 'row' }}>

        <View style={styles.BankDetailsContainer}>
          <InvoiceBankDetails
            item={{
              bankName: editedItem.bankName,
              bankReference: editedItem.bankReference,
            }}
          />
        </View>
        { editedItem.signature && (
          <View style={styles.SignatureContainer}>
            <InvoiceSignature
              item={{
                signature: editedItem.signature,
              }}
            />
          </View>
        )}
      </View>
      {editedItem.includePenalty && (
        <View style={styles.PenaltyContainer}>
          <Text>
            {
              "Late payment penalties at the annual rate of @rate% - No discount in the event of early payment"
                .replace(
                  "@rate",
                  Number.parseFloat(editedItem.penaltyRate).toFixed(2)
                )}
          </Text>
        </View>
      )}
    </Page>
  );
};

export default Preview;
