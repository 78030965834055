import React, { useEffect, useState } from "react";
import { Comment } from "./Comment";
import { Button, Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faComment, faHeart } from "@fortawesome/free-regular-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { socialService } from "utils/_services/social.services";
import ReactionComponent from "./ReactionComponent";
import ClapEmoji from 'assets/img/social/clap-emoji.png';
import LikeEmoji from 'assets/img/social/like-emoji.png';
import HeartEmoji from 'assets/img/social/heart-emoji.png';
import './PostActions.css';

const PostActions = ({ postPub }) => {
  let addCommentInput;
  const [isOpen, setIsOpen] = useState(false);
  const [isReactionsOpen, setIsReactionsOpen] = useState(false);
  const [hoveringReactions, setHoveringReactions] = useState(false);
  const [hoveringLike, setHoveringLike] = useState(false); // Track if mouse is on "Like" button

  const [comments, setComments] = useState(postPub.comments);
  const [comment, setComment] = useState("");
  const [userReaction, setUserReaction] = useState(null);

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (!hoveringReactions && !hoveringLike) {
      setIsReactionsOpen(false);
    }
  }, [hoveringReactions, hoveringLike]); // Runs when either of these values change

  const showReactions = () => setIsReactionsOpen(true);

  const handleMouseLeaveLike = () => {
    setHoveringLike(false);
  };

  const handleMouseLeaveReactions = () => {
    setHoveringReactions(false);
  };

  const commentClick = () => {
    setIsOpen(true);
    addCommentInput.focus();
  };

  const getReaction = () => {
    switch (userReaction) {
      case "like":
        return (<>
          <img src={LikeEmoji} alt="J'aime" className="mr-2" style={{ width: "25px", height: "25px" }} />
          <span style={{ color: "blue" }}>J'aime</span>
        </>
        );
      case "heart":
        return (<>
          <img src={HeartEmoji} alt="J'adore" className="mr-2" style={{ width: "25px", height: "25px" }} />
          <span style={{ color: "red" }}>J'adore</span>
        </>);
      case "congratulation":
        return (<>
          <img src={ClapEmoji} alt="Bravo" className="mr-2" style={{ width: "25px", height: "25px" }} />
          <span style={{ color: "green" }}>Bravo</span>
        </>);
      default:
        return (<>
          <FontAwesomeIcon icon={faThumbsUp} className="mr-2" />
          J'aime
        </>
        );
    }
  }

  function sendComment(e) {
    e.preventDefault();
    if (comment === "") {
      NotificationManager.error("Le commentaire ne doit pas être vide.");
      return;
    }

    const data = {
      content: comment,
      postId: postPub.id.toString(),
    };

    socialService
      .addComment(data)
      .then((response) => {
        if (response !== undefined) {
          const searchCommentsdata = {
            postId: postPub.id.toString(),
          };

          socialService
            .searchComments(searchCommentsdata)
            .then((searchResponse) => {
              setComments(searchResponse.data);
            });
          NotificationManager.success("Commentaire ajouté avec succes");
          setComment("");
        } else {
          NotificationManager.error("Problème lors de l'ajout du commentaire");
        }
      })
      .catch((err) =>
        NotificationManager.error("Problème lors de l'ajout du commentaire")
      );
  }

  return (
    <>
      <div className="mb-2 social-actions">
        {/* Button to show reactions on hover */}
        {/* Reactions collapse */}
        {isReactionsOpen && (
          <div
            className="floating-div"
            onMouseLeave={handleMouseLeaveReactions}
            onMouseEnter={() => setHoveringReactions(true)}
          >
            <ReactionComponent onChangeReaction={setUserReaction} />
          </div>
        )}
        <Button
          color="link"
          onClick={showReactions}
          onMouseEnter={() => {
            setHoveringLike(true);
            showReactions();
          }}
          onMouseLeave={handleMouseLeaveLike}
          className="text-muted btn-hover-gray btn social-btn"
        >
          {getReaction()}
        </Button>



        {/* Other post actions */}
        <Button
          onClick={commentClick}
          color="link"
          className="text-muted btn-hover-gray social-btn"
        >
          <FontAwesomeIcon icon={faComment} className="mr-2" />
          Commenter
        </Button>
        <Button
          onClick={toggle}
          color="link"
          className="text-muted text-decoration-underline btn-hover-gray social-btn"
        >
          <u> {comments ? comments.length : 0} Commentaire(s)</u>
        </Button>
      </div>

      {/* Comments section */}
      <Collapse className="" isOpen={isOpen}>
        <form onSubmit={sendComment}>
          <div className="input-group mb-2">
            <input
              placeholder="Ajouter un commentaire..."
              type="text"
              className="form-control"
              value={comment}
              ref={(ip) => (addCommentInput = ip)}
              onInput={(e) => setComment(e.target.value)}
              name="messageContent"
            />
            <div className="input-group-append">
              <button
                className="btn-right-icon"
                style={{ cursor: comment === "" ? "not-allowed" : "pointer" }}
                disabled={comment === ""}
                type="submit"
              >
                <FontAwesomeIcon
                  color={comment === "" ? "" : "blue"}
                  icon={faPaperPlane}
                  className="form-control-icon"
                />
              </button>
            </div>
          </div>
        </form>
        {comments
          ? comments.map((comment, index) => (
            <Comment key={index} postComment={comment} />
          ))
          : null}
      </Collapse>
      <NotificationContainer />
    </>
  );
};

export { PostActions };
