import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  title: {
    fontWeight: "bold",
    marginBottom: 10,
    fontSize: 12,
    textDecoration: "underline",
  },
  text: {
    fontSize: 10,
    marginBottom: 2,
    fontStyle: "italic",
  },
  image: {
    width: 100,
    alignSelf: "flex-end",
    alignItems: "flex-end"
  },
});

const InvoiceSignature = ({ item }) => {

  return (
    <View>
      <Text style={styles.title}>{"Signature"}</Text>
      <View style={{
        marginLeft: "auto"
      }}>
        <Image style={styles.image} src={item.signature} />
      </View>
    </View>
  );
}
export default InvoiceSignature;
