import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import "./ReactionComponent.css";
import ClapEmoji from 'assets/img/social/clap-emoji.png';
import LikeEmoji from 'assets/img/social/like-emoji.png';
import HeartEmoji from 'assets/img/social/heart-emoji.png';

const ReactionComponent = ({ onMouseEnter, onMouseLeave, onChangeReaction }) => {
    const [reaction, setReaction] = useState(null); // Store the selected reaction

    // Separate state for each tooltip visibility
    const [likeTooltipOpen, setLikeTooltipOpen] = useState(false);
    const [heartTooltipOpen, setHeartTooltipOpen] = useState(false);
    const [congratulationTooltipOpen, setCongratulationTooltipOpen] = useState(false);

    // Toggle functions for each tooltip
    const toggleLikeTooltip = () => setLikeTooltipOpen(!likeTooltipOpen);
    const toggleHeartTooltip = () => setHeartTooltipOpen(!heartTooltipOpen);
    const toggleCongratulationTooltip = () => setCongratulationTooltipOpen(!congratulationTooltipOpen);

    const handleReaction = (newReaction) => {
        const changedReaction = reaction === newReaction ? null : newReaction;
        setReaction(changedReaction);
        onChangeReaction(changedReaction);
    };

    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className="reaction-container">            

            <img id="like-btn" src={LikeEmoji} onClick={() => handleReaction('like')} alt="J'aime" className="like-emoji" style={{ width: "30px", height: "30px" }} />

            <Tooltip
                placement="top"
                isOpen={likeTooltipOpen}
                target="like-btn"
                toggle={toggleLikeTooltip}
                popperClassName="custom-tooltip"
            >
                J'aime
            </Tooltip>

            <img id="heart-btn" src={HeartEmoji} alt="J'adore" onClick={() => handleReaction('heart')} className="heart-emoji" style={{ width: "30px", height: "30px" }} />

            <Tooltip
                isOpen={heartTooltipOpen}
                target="heart-btn"
                toggle={toggleHeartTooltip}
                popperClassName="custom-tooltip"
            >
                J'adore
            </Tooltip>


            <img id="congratulation-btn" onClick={() => handleReaction('congratulation')} src={ClapEmoji} alt="Bravo" className="congratulation-emoji" style={{ width: "30px", height: "30px" }} />

            <Tooltip
                isOpen={congratulationTooltipOpen}
                target="congratulation-btn"
                toggle={toggleCongratulationTooltip}
                popperClassName="custom-tooltip"
            >
                Bravo
            </Tooltip>

        </div>
    );
};

export default ReactionComponent;
